import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';
import { mq, theme } from '../theme';

class TermsPage extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <LayoutTextPage title="Algemene Voorwaarden">
        <div
          css={mq({
            maxWidth: ['100%', '900px'],
            margin: 'auto',
            padding: '20px',
            fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            lineHeight: '1.6',
            color: '#333',
          })}
        >
          <div
            css={{
              backgroundColor: '#f9f9f9',
              padding: '20px',
              marginBottom: '20px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <div
              css={{
                '& p': {
                  marginBottom: '15px',
                },
                '& ul': {
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  padding: '15px 30px',
                  marginBottom: '15px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                },
                '& li': {
                  marginBottom: '10px',
                  paddingLeft: '10px',
                  position: 'relative',
                  '&:before': {
                    content: '"•"',
                    color: '#007bff',
                    position: 'absolute',
                    left: '-15px',
                    fontWeight: 'bold',
                  },
                },
                '& h2': {
                  color: '#007bff',
                  borderBottom: '1px solid #e0e0e0',
                  paddingBottom: '10px',
                  marginTop: '30px',
                  marginBottom: '15px',
                },
                '& h1': {
                  color: '#007bff',
                  borderBottom: '2px solid #007bff',
                  paddingBottom: '10px',
                  marginBottom: '20px',
                },
              }}
            >
              <h1>Algemene Voorwaarden Smart Sales Company</h1>

              <h2>I. Algemeen</h2>
              <h3>1. Definities</h3>
              <p>
                De in de Algemene Voorwaarden met hoofdletter geschreven termen hebben de hierna volgende betekenis.
              </p>
              <ul>
                <li><strong>Smart Sales Company B.V.:</strong> Smart Sales Company B.V., gevestigd te Amstelveen, ingeschreven bij de Kamer van Koophandel onder nummer 75356147.</li>
                <li><strong>Aanbieder:</strong> Een aanbieder van Producten en Diensten op het gebied van onder andere, maar niet beperkt tot, telecom, internet en TV waarmee de Klant door bemiddeling van Smart Sales Company een Overeenkomst aangaat.</li>
                <li><strong>Aanbod:</strong> De diensten van de Aanbieder met de daarop van toepassing zijnde voorwaarden.</li>
                <li><strong>Algemene Voorwaarden:</strong> De voorwaarden in het onderhavige document ten behoeve van de dienstverlening door Smart Sales Company.</li>
                <li><strong>Dienst(en):</strong> Een dienst op het gebied van internet en telecom aangeboden door Smart Sales Company en uitgevoerd door een Aanbieder.</li>
                <li><strong>Klant:</strong> De natuurlijke persoon of rechtspersoon die gebruik maakt van de diensten van Smart Sales Company.</li>
                <li><strong>Overeenkomst:</strong> Een verbintenis die tussen Klant en Aanbieder wordt aangegaan na bemiddeling door Smart Sales Company voor de levering van de Dienst door Aanbieder.</li>
                <li><strong>Persoonsgegevens:</strong> Alle informatie die herleidbaar is tot een persoon en die door de klant wordt verstrekt aan Smart Sales Company.</li>
                <li><strong>Product(en):</strong> Een product dat betrekking heeft op het gebied van internet en telecom aangeboden door Smart Sales Company.</li>
              </ul>

              <h2>2. Toepasselijkheid en uitvoering</h2>
              <p>2.1 Deze Algemene Voorwaarden zijn van toepassing op alle aanbiedingen, offertes, overeenkomsten en leveringen van Smart Sales Company, tenzij schriftelijk anders is overeengekomen.</p>
              <p>2.2 Door het invullen van zijn of haar gegevens op de website van Smart Sales Company en het aangaan van een overeenkomst, aanvaardt de klant de toepasselijkheid van deze Algemene Voorwaarden.</p>
              <p>2.3 Smart Sales Company heeft het recht deze Algemene Voorwaarden van tijd tot tijd te wijzigen. De gewijzigde voorwaarden zullen van toepassing zijn zodra deze op de website zijn gepubliceerd. Het is daarom raadzaam om deze Algemene Voorwaarden regelmatig te raadplegen.</p>
              <p>2.4 Afwijkingen van deze Algemene Voorwaarden kunnen slechts schriftelijk worden overeengekomen.</p>

              <h2>3. Totstandkoming overeenkomst</h2>
              <p>3.1 De Overeenkomst tussen Aanbieder en Klant komt tot stand op het moment en op de wijze zoals gesteld door Smart Sales Company en Aanbieder. Smart Sales Company en Aanbieder kunnen voorwaarden stellen aan de totstandkoming van de Overeenkomst. Smart Sales Company heeft geen invloed op deze voorwaarden en/of de totstandkoming, zoals gesteld door Aanbieder.</p>
              <p>3.2 Indien de Klant een Overeenkomst aangaat met een Aanbieder, of hiertoe een aanvraag doet, wordt de Klant geacht akkoord te gaan met de algemene voorwaarden van desbetreffende Aanbieder.</p>
              <p>3.3 Nadat de klant zijn gegevens heeft achtergelaten bij Smart Sales Company en toestemming heeft gegeven dat Smart Sales Company contact mag opnemen, zal Smart Sales Company een aanbieding doen aan de klant.</p>
              <p>3.4 Het is mogelijk dat Smart Sales Company een proefperiode aanbiedt aan de Klant. Wanneer er gebruik wordt gemaakt van een proefperiode, zijn de aanvullende voorwaarden zoals vermeld in het Aanbod van toepassing.</p>
              <p>3.5 Smart Sales Company behoudt zich het recht voor om aanvragen van een Klant te weigeren, bijvoorbeeld in het geval van twijfel over de juistheid van de opgegeven gegevens, betalingsproblemen in het verleden of andere gegronde redenen.</p>
              <p>3.6 Indien een Overeenkomst niet tot stand komt of wordt beëindigd, blijven de gegevens van de Klant slechts in overeenstemming met de toepasselijke wetgeving opgeslagen.</p>

              <h2>4. Verplichtingen van de Klant</h2>
              <p>4.1 De Klant is verantwoordelijk voor het verstrekken van juiste, volledige, en actuele informatie aan Smart Sales Company.</p>
              <p>4.2 De Klant dient alle verplichtingen voortvloeiend uit de Overeenkomst na te komen, waaronder maar niet beperkt tot het betalen van facturen aan de Aanbieder.</p>
              <p>4.3 Het is de verantwoordelijkheid van de Klant om ervoor te zorgen dat de geleverde diensten naar behoren kunnen functioneren, bijvoorbeeld door een geschikte infrastructuur beschikbaar te hebben.</p>
              <p>4.4 De Klant mag de diensten van Smart Sales Company en Aanbieders niet gebruiken voor illegale activiteiten of andere doeleinden die in strijd zijn met de wet.</p>

              <h2>5. Verplichtingen van Smart Sales Company</h2>
              <p>5.1 Smart Sales Company spant zich in om de diensten met zorg en deskundigheid te leveren en eventuele verstoringen of problemen zo spoedig mogelijk op te lossen.</p>
              <p>5.2 Smart Sales Company draagt geen verantwoordelijkheid voor de kwaliteit, beschikbaarheid of levering van diensten door Aanbieders.</p>
              <p>5.3 Smart Sales Company kan, indien nodig, technische ondersteuning bieden aan de Klant, binnen de grenzen van de Overeenkomst.</p>

              <h2>6. Prijzen en betalingen</h2>
              <p>6.1 De prijzen voor diensten worden vermeld in het Aanbod en zijn exclusief BTW, tenzij anders aangegeven.</p>
              <p>6.2 Betalingen dienen te worden voldaan binnen de termijn zoals vermeld op de factuur, tenzij schriftelijk anders overeengekomen.</p>
              <p>6.3 Indien de Klant niet tijdig betaalt, is Smart Sales Company gerechtigd wettelijke rente en incassokosten in rekening te brengen.</p>
              <p>6.4 Smart Sales Company behoudt zich het recht voor om prijzen te wijzigen. Eventuele wijzigingen worden tijdig aan de Klant gecommuniceerd.</p>

              <h2>7. Aansprakelijkheid</h2>
              <p>7.1 Smart Sales Company is niet aansprakelijk voor schade voortvloeiend uit of verband houdend met de diensten van Aanbieders.</p>
              <p>7.2 De aansprakelijkheid van Smart Sales Company is beperkt tot directe schade en tot een bedrag gelijk aan de door de Klant betaalde vergoeding over een periode van drie maanden voorafgaand aan de schadeveroorzakende gebeurtenis.</p>
              <p>7.3 Smart Sales Company is niet aansprakelijk voor indirecte schade, zoals gevolgschade, gederfde winst of verlies van gegevens.</p>

              <h2>8. Privacy en gegevensbescherming</h2>
              <p>8.1 Smart Sales Company verwerkt persoonsgegevens in overeenstemming met de toepasselijke wetgeving en het Privacybeleid.</p>
              <p>8.2 De Klant geeft toestemming voor de verwerking van zijn gegevens voor de uitvoering van de Overeenkomst.</p>
              <p>8.3 Smart Sales Company zal passende technische en organisatorische maatregelen treffen om persoonsgegevens te beschermen tegen verlies of onbevoegde toegang.</p>

              <h2>9. Duur en beëindiging</h2>
              <p>9.1 De Overeenkomst wordt aangegaan voor de duur zoals vermeld in het Aanbod.</p>
              <p>9.2 Beide partijen kunnen de Overeenkomst schriftelijk opzeggen met inachtneming van een opzegtermijn, zoals vermeld in het Aanbod.</p>
              <p>9.3 Smart Sales Company heeft het recht om de Overeenkomst onmiddellijk te beëindigen indien de Klant zijn verplichtingen niet nakomt of in geval van faillissement, surseance van betaling of liquidatie van de Klant.</p>
              <p>9.4 Bij beëindiging van de Overeenkomst blijven verplichtingen die naar hun aard bedoeld zijn om voort te duren, onverminderd van kracht.</p>

              <h2>10. Toepasselijk recht en geschillen</h2>
              <p>10.1 Op deze Overeenkomst is Nederlands recht van toepassing.</p>
              <p>10.2 Geschillen die voortvloeien uit of verband houden met deze Overeenkomst zullen worden voorgelegd aan de bevoegde rechter in het arrondissement waar Smart Sales Company is gevestigd, tenzij anders vereist door dwingend recht.</p>
              <p>10.3 Partijen zullen zich inspannen om geschillen eerst in der minne op te lossen alvorens een beroep te doen op de rechter.</p>



              {/* Additional sections follow similar structure */}
            </div>
          </div>
        </div>
      </LayoutTextPage>
    );
  }
}

export default TermsPage;
